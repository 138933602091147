import { Directive, ElementRef, Renderer2, effect, inject } from '@angular/core';
import { AppService } from '@shared/services/app.service';

@Directive({
    standalone: true,
    selector: '[hasSideMenu]',
})
export class HasSideMenuDirective {
    private el: ElementRef = inject(ElementRef);
    private renderer: Renderer2 = inject(Renderer2);

    hasSideMenu = inject(AppService).hasSideMenu;

    constructor() {
        effect(() => {
            if (this.hasSideMenu()) this.renderer.addClass(this.el.nativeElement, 'has-side-menu');
            else this.renderer.removeClass(this.el.nativeElement, 'has-side-menu');
        });
    }
}
