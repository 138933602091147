import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, Signal } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button';
import { IMenu } from '@shared/components/menu/menu.interface';
import { HasSideMenuDirective } from '@shared/directives/has-side-menu.directive';
import { IsMobileDirective } from '@shared/directives/is-mobile.directive';
import { IRouteAccessConfig, TPageType } from '@shared/interfaces/route.interface';
import { UserModel } from '@shared/models';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { AppRouteService } from '@shared/services/app-route.service';
import { AppStyles } from '@shared/services/app-styles.service';
import { AppService } from '@shared/services/app.service';
import { ToastService } from '@shared/services/toast.serivce';
import { UserService } from '@shared/services/user.service';
import { translations } from '@shared/utils/translations';

@Component({
    selector: 'app-menu-header',
    standalone: true,
    templateUrl: 'menu-header.component.html',
    styleUrl: 'menu-header.component.scss',
    imports: [
        TranslateModule,
        RouterLink,
        CommonModule,
        RouterLinkActive,
        MatBadge,
        MatDivider,
        MatIcon,
        MatIconButton,
        MatMenuTrigger,
        ButtonComponent,
        MatMenu,
        MatButton,
        MatMenuItem,
    ],
    hostDirectives: [IsMobileDirective, HasSideMenuDirective],
})
export class MenuHeaderComponent {
    protected readonly translations = translations;
    private appRouteService: AppRouteService = inject(AppRouteService);
    private userService: UserService = inject(UserService);
    private appStyles: AppStyles = inject(AppStyles);
    private router: Router = inject(Router);
    appService: AppService = inject(AppService);
    appNavigationService: AppNavigationService = inject(AppNavigationService);
    public toastService: ToastService = inject(ToastService);

    menuState = inject(AppService).menuState;
    isMobile = inject(AppService).isMobile;
    user = inject(UserService).user;
    styles = this.appStyles.styles;
    isSideMenu = inject(AppService).hasSideMenu;

    settings: IMenu | undefined = this.getMenuItem('settings');
    profile: IMenu | undefined = this.getMenuItem('profile');

    iconSize: number = 24;
    disabledAnimation: boolean = true;
    itemHover: { [key: string]: boolean } = {};
    eventListener: any;

    navigateTo = (page: TPageType) => this.appNavigationService.navigateTo(page, { from: 'menu' });

    menu: Signal<IMenu[]> = computed(() => {
        const user = this.user();
        const menu: IMenu[] = [];

        for (const route in this.appRouteService.routeAccess) {
            const pageType: TPageType = route as TPageType;
            //if (pageType === 'settings') continue;
            if (pageType === 'profile') continue;
            if (pageType === 'settings') continue;
            const item = this.getMenuItem(pageType, user);
            if (item) menu.push(item);
        }
        return menu;
    });

    constructor() {
        effect(
            () => {
                if (this.isMobile() && this.menuState()) {
                    this.disabledAnimation = false;
                    setTimeout(() => {
                        this.eventListener = () => {
                            this.triggerMenu();
                            setTimeout(() => {
                                this.disabledAnimation = true;
                            }, this.appStyles.getNumberFromTime('themeDurationSlow'));
                        };
                        document.addEventListener('click', this.eventListener);
                    }, this.appStyles.getNumberFromTime('themeDurationSlow'));
                }
            },
            {
                allowSignalWrites: true,
            },
        );
        effect(
            () => {
                if (!this.isMobile() && this.menuState()) {
                    this.disabledAnimation = true;
                    this.triggerMenu();
                }
            },
            {
                allowSignalWrites: true,
            },
        );
    }

    getMenuItem(pageType: TPageType, user?: UserModel) {
        const config: IRouteAccessConfig<any> = this.appRouteService.routeAccess[pageType];
        const routeAvailable: boolean = this.appRouteService.getRouteAvailability(
            config,
            user ?? this.userService.user(),
        );

        // main path
        if (routeAvailable) {
            return {
                page: pageType,
                tKey: config.title ?? '',
                url: this.appRouteService.getPath(pageType),
                roles: config.roles,
                icon: config.icon,
                notifications: '',
            };
        }
        return undefined;
    }

    triggerMenu() {
        if (!this.isMobile()) return;

        this.appService.triggerMenu();
        if (this.eventListener) {
            document.removeEventListener('click', this.eventListener);
            this.eventListener = null;
        }
    }

    public logout() {
        this.userService.logoutUser().then(
            () => {
                this.toastService.success(this.translations.toast.success.auth.logout);
                this.router.navigate([this.appRouteService.routeAccess.landing.routeConfig.path]).then();
            },
            err => {
                this.toastService.error(this.translations.toast.error.auth.logout);
            },
        );
    }

    public toggleSideMenu() {
        this.appService.sideMenuOpen.set(!this.appService.sideMenuOpen().valueOf());
    }

    public goToStartPage() {
        this.router.navigate([this.appRouteService.routeAccess.petitions.routeConfig.path]).then();
    }
}
